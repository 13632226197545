import * as React from "react"
import {Link, useStaticQuery, graphql} from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import * as Styles from "../styles/charge.module.scss"

const ChargePage = () => {
    const DATA = useStaticQuery(
        graphql`
            query {
                site { 
                    siteMetadata {
                        divisionJa { gyosei },
                        menuJa { charge }
                    }
                }
            }
        `
    )
    const GYOSEI_JA = DATA.site.siteMetadata.divisionJa.gyosei 
    const CHARGE_JA = DATA.site.siteMetadata.menuJa.charge
    return (
        <Layout division='gyosei'>
            <Seo title={`${CHARGE_JA} | ${GYOSEI_JA}`} />
            <h1 className="gyosei">{CHARGE_JA}</h1>
            <div className={Styles.gyosei}>
                <div className={Styles.table}>
                    <div className={Styles.table_body}>
                        <h2 className="gyosei">相談</h2>
                        <div className={Styles.table_col}>
                            <span>初回</span>
                            <span>1,000円/回</span>
                            <span className={Styles.memo}>受任時着手金に充当</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>2回目以降</span>
                            <span>5,000円/回</span>
                            <span className={Styles.memo}>受任時着手金に充当</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span >顧問</span>
                            <span >30,000円～/月</span>
                            <span className={Styles.memo}>
                                相談回数制限なし<br />
                                契約中他の料金1割引
                            </span>
                        </div>
                    </div>
                </div>
                <div className={Styles.table}>
                    <div className={Styles.table_body}>
                        <h2 className="gyosei">会計記帳</h2>
                        <div className={Styles.table_col}>
                            <span>現状確認等</span>
                            <span>50,000円～</span>
                            <span>初月のみ</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>記帳<br />(~100仕訳)</span>
                            <span>20,000円/月</span>
                            <span>100仕訳以上は<br />10,000円/50仕訳</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>領収書等整理<br />(~50枚)</span>
                            <span>10,000円/月</span>
                            <span>50枚以上は<br />10,000円/50枚</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>領収書等回収</span>
                            <span>5,000円/回</span>
                            <span></span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>税理士事務所<br />連絡代行</span>
                            <span>10,000円～/月</span>
                            <span></span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>財務諸表作成</span>
                            <span>30,000円/回</span>
                            <span></span>
                        </div>
                    </div>
                </div>
                <div className={Styles.plan_button}>
                    <a href="../../plan#bookkeeping" target="_blank">
                        <button>お得なプランのご案内</button>
                    </a>
                </div>
                <div className={Styles.table}>
                    <div className={Styles.table_body}>
                        <h2 className="gyosei">婚前契約書</h2>
                        <div className={Styles.table_col}>
                            <span>作成支援</span>
                            <span>50,000円～</span>
                            <span></span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>公正証書化手続</span>
                            <span>30,000円～</span>
                            <span>別途公証人手数料が必要</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>公正証書証人</span>
                            <span>10,000円/人</span>
                            <span>別途交通費が必要</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>カウンセリング</span>
                            <span>5,000円～</span>
                            <span>相手未定でも可</span>
                        </div>
                    </div>
                </div>
                <div className={Styles.table}>
                    <div className={Styles.table_body}>
                        <h2 className="gyosei">遺言書</h2>
                        <div className={Styles.table_col}>
                            <span>作成支援</span>
                            <span>50,000円～</span>
                            <span></span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>相続人調査</span>
                            <span>50,000円～</span>
                            <span>相続人数により変動</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>相続財産調査</span>
                            <span>50,000円～</span>
                            <span>相続財産数により変動</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>公正証書化手続</span>
                            <span>30,000円～</span>
                            <span>別途公証人手数料が必要</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>公正証書証人</span>
                            <span>10,000円/人</span>
                            <span>別途交通費が必要</span>
                        </div>
                        <div className={Styles.table_col}>
                            <span>遺産執行手続</span>
                            <span>300,000円~</span>
                            <span>別途士業費用等が<br />必要な場合有り</span>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}
export default ChargePage
